import { render, staticRenderFns } from "./ProductCardInfo.vue?vue&type=template&id=4b5d68ae&scoped=true"
import script from "./ProductCardInfo.vue?vue&type=script&lang=js"
export * from "./ProductCardInfo.vue?vue&type=script&lang=js"
import style0 from "./ProductCardInfo.vue?vue&type=style&index=0&id=4b5d68ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b5d68ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Skeleton: require('/codebuild/output/src1025155862/src/client/components/skeleton/index.vue').default,ProductCardButtonsList: require('/codebuild/output/src1025155862/src/client/components/productCard/ProductCardButtonsList.vue').default,Loader: require('/codebuild/output/src1025155862/src/client/components/Loader.vue').default})
